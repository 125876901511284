<template>
  <standard-page :title="processDefinition.name" v-if="processDefinition">
    <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'provenances-home'}">Provenance</router-link>
      </span>
      <span>
        <router-link :to="{ name: 'available-process'}">Start New Process</router-link>
      </span>
      <span v-if="processDefinition">
        <router-link :to="{ name: 'available-process'}">Process: {{processDefinition.name | truncate(10, '.....')}}</router-link>
      </span>
      <span>New</span>
    </template>
    <template v-slot:content>
      <div class="az-content-label mg-b-5">Start New Process</div>
      <div class="mg-b-20" v-html="$DEFINITIONS.provenance.newProcess"></div>
      <div class="pd-30 pd-sm-40 bg-gray-200 wd-xl-75p">
        <div class="row row-xs">
          <div class="col-md-10">
            <input class="form-control" placeholder="Enter Process name" type="text" v-model="process.name">
          </div>
          <div class="col-md mg-t-10 mg-md-t-0">
            <button @click="createProcess" class="btn rounded btn-az-primary btn-block">Submit
            </button>
          </div>
        </div>
      </div>
    </template>
  </standard-page>
</template>

<script>
import EventBus from "@/event-bus";

export default {
  name: "NewProcess",
  props: ['registryid', 'processdefinitionid'],
  data() {
    return {
      processDefinition: null,
      process: {
        name: null
      },
      firstProcessDefinition: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getProcessDefinition();
      await this.getFirstProcessDefinitionStep();
    },
    async getProcessDefinition() {
      try {
        let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}`);
        this.processDefinition = data;
      } catch (e) {
        this.$toastr.e("Error retrieving ProcessDefinition", "Error");
      } finally {

      }
    },
    async getFirstProcessDefinitionStep() {
      try {
        let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/definition_steps?sort_by=order&order=ASC&page=0&per_page=1`);
        this.firstProcessDefinition = data ? data.process_definition_steps[0] : null;
      } catch (e) {

      }
    },
    async createProcess() {
      if (this.process.name) {
        try {
          EventBus.$emit('openLoader');
          let { data } = await this.$provenance.post(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes`, this.process);
          this.$toastr.s("Process Successfully created!", 'Success');
          await this.addProcessStep(data.id);
          EventBus.$emit('updateProcessCount');
          EventBus.$emit('updatePendingStepCount');
          this.$router.push({ name: 'ongoing-process-steps', params: { registryid: this.registryid, processdefinitionid: this.processdefinitionid, processid: data.id } });
        } catch (e) {
          this.$toastr.e("Process creation failed", 'Error');
        } finally {
          EventBus.$emit('closeLoader');
        }
      } else {
        this.$toastr.e("Please provide a name first!", 'Failed');
      }
    },
    async addProcessStep(processid) {
      try {
        await this.$provenance.post(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${processid}/process_steps`, {
          processDefinition_step: this.firstProcessDefinition ? this.firstProcessDefinition.processDefinition_step : null,
          attributes: []
        });
      } catch (e) {
      } finally {
      }
    },
  }
}
</script>

<style scoped>
</style>